import request from '/src/utils/request'

export function queryTCT(param){
  return request({
    url: `/api/tct/getAnalysis`,
    method: 'post',
    data: param
  })
}
export function collectTonnageTCT(param) {
  return request({
    url: `/tct/tct/collectTonnageTCT`,
    method: 'get',
    data: param
  })
}
export function canclecollectTonnageTCT(param) {
  return request({
    url: `/tct/tct/canclecollectTonnageTCT`,
    method: 'get',
    data: param
  })
}
export function getEmailDetail(param) {
  return request({
    url: `/tct/tct/getEmailDetail`,
    method: 'get',
    data: param
  })
}
export function matchCargo(param) {
  return request({
    url: `/tct/tct/matchCargo`,
    method: 'get',
    data: param
  })
}
export function searchmatchCargo(param) {
  return request({
    url: `/tct/tct/matchCargo`,
    method: 'post',
    data: param
  })
}
export function matchTCT(param) {
  return request({
    url: `/tct/tct/matchTCT`,
    method: 'get',
    data: param
  })
}
export function searchmatchTCT(param) {
  return request({
    url: `/tct/tct/matchTCT`,
    method: 'post',
    data: param
  })
}
export function collectTonnageCargo(param) {
  return request({
    url: `/tct/tct/collectTonnageCargo`,
    method: 'get',
    data: param
  })
}
export function cancelCollectTonnageCargo(param) {
  return request({
    url: `/tct/tct/cancelCollectTonnageCargo`,
    method: 'get',
    data: param
  })
}
export function updateTCT(param) {
  return request({
    url: `/api/tct/save`,
    method: 'post',
    data: param
  })
}
export function deleteTCT(param) {
  return request({
    url: `/api/tct/delete`,
    method: 'post',
    data:param
  })
}
//my, page, size
export function searchTCT(param) {
  return request({
    url: `/api/tct/getcard`,
    method: 'post',
    data: param
  })
}
export  function searchTctpayed(param) {
  return request({
    url:`/api/tct/getpaydetil`,
    method:'post',
    data:param
  })
}

export function searchUserTCT(param) {
  return request({
    url: `/api/tct/getmycard`,
    method: 'post',
    data: param
  })
}
export function searchCollTCT(param) {
  return request({
    url: `/api/tct/selectTct/getcard`,
    method: 'post',
    data: param
  })
}
//根据tctid查找tct信息
export  function searchTCTbyId(param) {
  return request({
    url:`/api/tct/getdetil/${param}`,
    method:'post'
  })
}
export function collecttct(param) {
  return request({
    url: `/api/tct/selectTct/save`,
    method: 'post',
    data: param
  })
}

export function cancelCollecttct(param) {
  return request({
    url: `/api/tct/selectTct/delete`,
    method: 'post',
    data:param
  })
}

export function addTCT(param) {
  return request({
    url: `/api/tct/save`,
    method: 'post',
    data: param
  })
}

//文件列表
export function getFilelist(param) {
  return request({
    url: `/api/tct/accessory/getListById`,
    method: 'post',
    data:param
  })
}
//删除文件
export function deletetctDocument(params) {
  return request({
    url: `/api/tct/accessory/userDeleteFile`,
    method: 'post',
    data:params
  })
}
//图片下载
export function downloadtctDocument(params) {
  return request({
    url: `/api/tct/accessory/downloadFile`,
    method: 'post',
    data:params,
    responseType: "arraybuffer"
  })
}
//pdf下载
export function downloadtctPdf(params) {
  return request({
    url: `/api/tct/accessory/downloadFile`,
    method: 'post',
    data:params,
    responseType: "blob"
  })
}
