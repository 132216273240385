<template>
  <div class="echarts">
    <!-- 缩略图 -->
    <div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card @click="goDetail(1,'受载期时间段')" class="itemCard"><div id="laycanData" class="echartCard"></div><span class="cardTitle">受载期时间段</span></el-card>
        </el-col>
        <el-col :span="6">
          <el-card @click="goDetail(2,'交还船海域')" class="itemCard"><div id="seaarea" class="echartCard"></div><span class="cardTitle">交还船海域</span></el-card>
        </el-col>
        <el-col :span="6">
          <el-card @click="goDetail(3,'船舶类型')" class="itemCard"><div id="port" class="echartCard"></div><span class="cardTitle">船舶类型</span></el-card>
        </el-col>
        <el-col :span="6">
          <el-card @click="goDetail(4,'DWT')" class="itemCard"><div id="dwt" class="echartCard"></div><span class="cardTitle">DWT</span></el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6"><el-card @click="goDetail(5,'租期')" class="itemCard"><div id="duration" class="echartCard"></div><span class="cardTitle">租期</span></el-card></el-col>
      </el-row>
    </div>
    <!-- 详细大图 -->
    <el-dialog v-model="dialogTableVisible" :title="dialogTitle" @close="loading = true" fullscreen="true">
      <div v-loading="loading">
        <el-row>
          <el-col :span="18"><div id="detailChart" style="height: 500px;"></div></el-col>
          <el-col :span="6">
            <div>
              <el-date-picker v-model="datePickerData" type="daterange" size="small"  unlink-panels value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" :shortcuts="pickerOptions" @change="updateOpenTimeChart"> </el-date-picker>
              <div style="margin-top: 20px">
                <!-- <b>分组：&nbsp&nbsp&nbsp</b> -->
                <el-radio-group v-model="period" size="small" @change="changePeriod()" :disabled="periodDisabled">
                  <el-radio-button label="自动"/>
                  <el-radio-button label="按年"/>
                  <el-radio-button label="按月"/>
                  <el-radio-button label="按日"/>
                </el-radio-group>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {queryTCT} from '../../api/tct'



const shortcuts = [{ text: '本月',
  value: [new Date(new Date().getFullYear(),new Date().getMonth(),1).getTime(), new Date().getTime()],
},
  {
    text: '今年',
    value: [new Date(new Date().getFullYear(), 0).getTime(),new Date().getTime()]
  },
  {
    text: '近六个月',
    value: [new Date((new Date()).getMonth < 6 ? new Date().getFullYear() - 1 : new Date().getFullYear(),new Date().getMonth() - 6).getTime(),new Date().getTime()]
  },
]


export default {
  data(){
    return{
      period: '自动',
      periodDisabled:true,
      loading: true, // 对话框加载
      dialogTableVisible: false, // 显示大图
      dialogTitle: '', //大图题目
      currentChart: '', // 当前对话框显示的图表
      // OpenTiemYearData: '2020', //默认2020年的数据
      pickerOptions: shortcuts,
      datePickerData: ['2020-01-01','2020-12-31'],
      openTimePeriod: ['2020-01-01','2020-12-31'],
      openTimeDisabled: false,
      // 受载期时间段图表变量
      laycanDataChart: {},
      laycanData: {},
      laycanDataAxis: [],
      laycanDataData: [],
      params:{
        laycanStartDateAnalysis: '2020-01-01',//开始
        laycanEndDateAnalysis: '2020-12-31',//结束
        auto:false,
        laycanTimeDataAnalysis: true, // 按受载期时间段分组
        laycanTimeMonthDataAnalysis: true,// 按月分组
        laycanTimeYearDataAnalysis:false,//按年分组
        laycanTimeDayDataAnalysis:false,//按日分组
      },
      paramsSeaarea:{
        dereseaareaAnalysis: true,//交还船海域
        deliverySeaareaEname:'',//交船海域英文名
        redeliverySeaareaEname:'',//还船海域英文名
        startDateAnalysis: '2020-01-01',
        endDateAnalysis: '2020-12-31',

      },
      paramsPort:{
        shipTypeAnalysis :true,
        shipTypeEname:'',
        shipTypeCname:'',
        auto:true,
      },
      paramsDwt:{
        startDateAnalysis: "2020-01-01",
        endDateAnalysis: "2020-12-31",
        dwtStyleIdAnalasis: true,//吨位类型id
        minDwt:'-1',
        maxDwt:'-2',
      },
      paramsDuration: {
        durationAnalysis:true,
        duration:120,
      },
      // data: [],
      // 交还船海域图表变量
      seaareaChart: {},
      seaareaData: {},
      deliveryData: [],
      redeliveryDataData:[],
      seaareaDataAxis: [],
      // 交还船港口图表变量
      portChart: {},
      portData: {},
      portDataData: [],
      portDataAxis: [],
      // 载重吨图表变量
      dwtChart:{},
      dwtData: {},
      dwtDataData: [],
      dwtDataAxis: [],
      // 租期图表变量
      durationData: {},
      durationChart: {},
      durationDataData: [],
      durationDataAxis: [],
      noDataOption:{
        title:{
          text: '暂无数据',
          x: 'center',
          y: 'center',
          textStyle: {
            color: '#65ABE7',
            fontWeight: 'normal',
            fontSize: 16
          }
        },
        xAxis:{
          data: []
        },
        yAxis:{
          name: ''
        },
        series: []
      },
      detailChart:{},
      main: Object,
      obj: Object,
      echarts: {},
    }
  },
  methods:{
    initLaycan(that,myChart){
      if(that.laycanDataData.length == 0){
        that.laycanData = that.noDataOption;
        return ;
      }
      that.laycanData = {
        xAxis: {
          data: that.laycanDataAxis,
          axisLabel: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          name:'个数',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: false
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            name: '个数',
            data: that.laycanDataData,
            barWidth: '60%',
            label: false,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
          },
        ]
      };
    },
    initSeaarea(that,myChart){
      if(that.deliveryData.length == 0){
        that.seaareaData = that.noDataOption;
        return ;
      }
      that.seaareaData = {
        legend: {},
        xAxis: {
          type: 'value',
          // boundaryGap: [0, 0.01]
          axisLabel: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'category',
          data: this.seaareaDataAxis,
          axisLabel: false
        },
        series: [
          {
            type: 'bar',
            data:  this.redeliveryDataData,
          },
          {
            name: '交船海域-还船海域',
            type: 'bar',
            data: this.deliveryData
          }
        ]
      };
    },
    initPort(that,myChart){
      if(that.portDataData.length == 0){
        that.portData = that.noDataOption;
        return ;
      }
      that.portData = {
        legend: {},
        xAxis: {
          type: 'value',
          axisLabel: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'category',
          data: this.portDataAxis,
          axisLabel: false
        },
        series: [
          {
            name: '船舶类型',
            type: 'bar',
            data: this.portDataData,
          },
          {
            type: 'bar',
            data: []
          }
        ]
      };
    },
    initdwt(that,myChart){
      if(that.dwtDataData.length == 0){
        that.dwtData = that.noDataOption;
        return ;
      }
      that.dwtData = {
        xAxis: [
          {
            name: 'dwt/吨',
            type: 'category',
            data: that.dwtDataAxis,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: false
            // axisLabel:{
            //   rotate: 30
            // }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '个数',
            axisLabel: false
          },
          {
            type: 'value',
            show: false
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: that.dwtDataData,
            barWidth: '60%',
          },
          {
            name: '个数',
            type: 'line',
            yAxisIndex: 1,
            data: that.dwtDataData
          }
        ]
      };
    },
    initDuration(that,myChart){
      if(that.durationDataData.length == 0){
        that.durationData = that.noDataOption;
        return ;
      }
      that.durationData = {
        xAxis: {
          name:'租期(天)',
          type: 'category',
          data: that.durationDataData,
          axisLabel: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            show: false
          },
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        yAxis: {
          name:'个数',
          type: 'value',
          axisLabel: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            data: that.durationDataAxis,
            showBackground: true,
            type: 'bar',
            barWidth: '60%',
            label: false,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
          }
        ]
      };
    },
    updateOpenTimeChart(){
      this.openTimePeriod[0] = this.datePickerData[0].getFullYear() + '-' + (this.datePickerData[0].getMonth() + 1) + '-' + this.datePickerData[0].getDate();
      this.openTimePeriod[1] = this.datePickerData[1].getFullYear() + '-' + (this.datePickerData[1].getMonth() + 1) + '-' + this.datePickerData[1].getDate();
      this.params.laycanStartDateAnalysis = this.openTimePeriod[0];
      this.params.laycanEndDateAnalysis = this.openTimePeriod[1];
      this.paramsSeaarea.startDateAnalysis = this.openTimePeriod[0];
      this.paramsSeaarea.endDateAnalysis = this.openTimePeriod[1];
      this.paramsDwt.startDateAnalysis = this.openTimePeriod[0];
      this.paramsDwt.endDateAnalysis = this.openTimePeriod[1];
      // 1、请求后端数据
      switch(this.currentChart){
        case 1:
          this.queryLaycan();
          break;
        case 2:
          this.querySeaarea();
          break;
        case 3:
          this.queryPort();
          break;
        case 4:
          this.queryDwt();
          break;
        case 5:
          this.queryDuration();
          break;
      }
    },
    changePeriod(){
      if(this.period == '按年'){
        this.changePeriodParams(false,true,false,false);
      } else if(this.period == '按月'){
        this.changePeriodParams(false,false,true,false);
      } else if(this.period == '按日'){
        this.changePeriodParams(false,false,false,true);
      } else {
        this.changePeriodParams(true,false,false,false);
      }
      queryTCT(this.params).then(response => {
        this.data = response.datas;
        this.laycanDataAxis=[]
        this.laycanDataData=[]
        if(this.data){
          // 设置各个值
          for(let i = 0;i < this.data.length;i++){
            let x = this.data[i].laycanTimeYear == undefined ? (this.data[i].laycanTimeMonth == undefined ? this.data[i].laycanTimeDay : this.data[i].laycanTimeMonth) : this.data[i].laycanTimeYear;
            this.laycanDataAxis[i] = x;
            this.laycanDataData[i] = this.data[i].num;
          }
          // 初始化图表
          this.initLaycan(this,this.laycanDataChart);
          this.laycanDataChart.setOption(this.laycanData);
          var temp = {xAxis:{data: this.laycanDataAxis},series:[{data: this.laycanDataData}]}
          this.detailChart.setOption(temp);
        }
      })
    },
    changePeriodParams(a,y,m,d){
      this.params.auto = a;
      this.params.laycanTimeYearDataAnalysis = y;
      this.params.laycanTimeMonthDataAnalysis = m;
      this.params.laycanTimeDayDataAnalysis = d;
    },
    goDetail(index,title){
      this.dialogTableVisible = true;
      this.dialogTitle = title;
      this.currentChart = index;
      if(index == 1){
        this.periodDisabled = false;
      } else {
        this.periodDisabled = true;
      }
      setTimeout(()=>{
        this.initDetailChart(index)
        this.loading = false
      },500)
    },
    closeDialog(){
      this.loading = true
      this.detailChart.dispose();
    },
    initDetailChart(index){
      var echarts = require('echarts')
      var chartElement = document.getElementById('detailChart')
      // charts.dispose();
      this.detailChart = echarts.init(chartElement)
      switch(index){
        case 1:
          var temp = this.laycanData;
          // 设置详细图标的格式
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'top'}
          this.detailChart.setOption(temp,true);
          break;
        case 2:
          var temp = this.seaareaData;
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'right'}
          temp.series[1].label = {show: true,position:'right'}
          temp.series[0].barGap = 0;
          this.detailChart.setOption(this.seaareaData,true);
          break;
        case 3:
          var temp = this.portData;
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'right'}
          temp.series[1].label = {show: true,position:'right'}
          temp.series[0].barGap = 0;
          this.detailChart.setOption(this.portData,true);
          break;
        case 4:
          var temp = this.dwtData;
          temp.xAxis[0].axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.xAxis[0].name = 'dwt/吨'
          temp.yAxis[0].show = true
          temp.series[0].label = {show: true,position:'top'}
          temp.tooltip = {trigger: 'axis',axisPointer: {type: 'cross',crossStyle: {color: '#999'}}}
          this.detailChart.setOption(temp,true);
          break;
        case 5:
          var temp = this.durationData;
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'top'}
          this.detailChart.setOption(this.durationData,true);
          break;
      }

    },
    queryLaycan(){
      queryTCT(this.params).then(response => {
        this.data = response.datas;
        this.laycanDataAxis=[]
        this.laycanDataData=[]
        if(this.data){
          // 设置各个值
          for(let i = 0;i < this.data.length;i++){
            let x = this.data[i].laycanTimeYear == undefined ? (this.data[i].laycanTimeMonth == undefined ? this.data[i].laycanTimeDay : this.data[i].laycanTimeMonth) : this.data[i].laycanTimeYear;
            this.laycanDataAxis[i] = x;
            this.laycanDataData[i] = this.data[i].num;
          }
          // 初始化图表
          this.initLaycan(this,this.laycanDataChart);
          this.laycanDataChart.setOption(this.laycanData);
        }
      })
    },
    querySeaarea(){
      queryTCT(this.paramsSeaarea).then(response => {
      //  console.log(response)
        if(response){
          // 设置各个值
          this.seaareaDataAxis = [];
          this.deliveryData = [];
          for(let i = 0;i < response.datas.length;i++){
            let result = response.datas[i].deliverySeaareaEname+'-'+response.datas[i].redeliverySeaareaEname
            this.seaareaDataAxis.push(result)
            this.deliveryData.push(response.datas[i].num)
          }
          // 初始化图表
          this.initSeaarea(this,this.seaareaChart)
          this.seaareaChart.setOption(this.seaareaData);
        }
      })
    },
    queryPort(){
      queryTCT(this.paramsPort).then(response => {
        // 设置各个值
        this.portDataData = [];
        this.portDataAxis = [];
          for(let i = 0;i < response.datas.length;i++){
            this.portDataAxis.push(response.datas[i].shipTypeEname)
            this.portDataData.push(response.datas[i].num)
          }
        // 初始化图表
        this.initPort(this,this.portChart)
        this.portChart.setOption(this.portData);
      })
    },
    queryDwt(){
      queryTCT(this.paramsDwt).then(response => {
       // console.log(response)
        this.dwtDataData = []
        this.dwtDataAxis = []
        for(let i = 0;i < response.datas.length;i++){
          this.dwtDataAxis.push(response.datas[i].minDwt + '-' + response.datas[i].maxDwt)
          this.dwtDataData.push(response.datas[i].num)
        }
        this.initdwt(this,this.dwtChart)
        this.dwtChart.setOption(this.dwtData);
      })
    },
    queryDuration(){
      this.paramsDuration.duration=Number(this.paramsDuration.duration)
      queryTCT(this.paramsDuration).then(response => {
        //console.log(response)
        this.durationDataData = []
        this.durationDataAxis = []
        for(let i = 0;i < response.datas.length;i++){
          this.durationDataData.push(response.datas[i].duration)
          this.durationDataAxis.push(response.datas[i].num)
        }
        this.initDuration(this,this.durationChart)
        this.durationChart.setOption(this.durationData);
      })
    },

  },
  mounted(){
    var echarts = require('echarts')
    var that = this;
    this.laycanDataChart = echarts.init(document.getElementById('laycanData'))
    this.seaareaChart = echarts.init(document.getElementById('seaarea'))
    this.portChart = echarts.init(document.getElementById('port'))
    this.dwtChart = echarts.init(document.getElementById('dwt'))
    this.durationChart = echarts.init(document.getElementById('duration'))
    this.queryLaycan();
    this.querySeaarea();
    this.queryPort();
    this.queryDwt()
    this.queryDuration()
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
}

body, ul, li, a, p, div {
  /*慎删*/
  padding: 0px;
  margin: 0px;
}
.itemCard{
  cursor:pointer;
  margin-bottom: 10px;
}
.echartCard{
  height: 200px;
  width: 100%;
}
.cardTitle{
  font-weight: 700;
  text-align: center;
}
</style>
